.HistoryItem {
  background-color: var(--bg-trip);
  padding: 16px 24px 8px 24px;
  border-radius: 28px;
  margin-bottom: 8px;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-trip);
  padding-bottom: 10px;
}

.icon {
  margin-right: 8px;
  fill: var(--primary-color);

  circle {
    stroke: var(--primary-color);
    fill: none;
  }
}

.price {
  display: flex;
  flex: 1 1 auto;
  font: var(--font-xl);
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  line-height: 30px;
  letter-spacing: 0.1px;
}

.currency {
  font-size: 22px;
}

.routeContainer {
  padding-top: 10px;
  position: relative;
}

// Стили для незавершенного заказа
.failureTrip {
  background-color: var(--bg-fail-trip);

  .header {
    .icon {
      fill: var(--bg-button-disabled);
      circle {
        stroke: var(--bg-button-disabled);
        fill: none;
      }
    }

    .price {
      color: var(--bg-button-disabled);
    }

    .orderBtn {
      background-color: var(--bg-button-disabled);
      color: var(--text-color-11)
    }
  }
}
