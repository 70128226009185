.MapPointsWrapper {
  position: relative;
  z-index: 10;
}

.mapPoints {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  background: var(--bg-color-primary);
  border-radius: 28px 28px 0 0;
  padding: 18px 16px 30px 16px;
}

.MapWrapper {
  height: 100%;
  margin-bottom: -25px;
}

.overflowMap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden; 
}

.overflowMapNone {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dontShowMapOverflow {
  display: flex;
  flex-direction: column;
  height: 10px;
  overflow-y: hidden; 
}

.dontShowMap {
  display: flex;
  flex-direction: column;
  height: 10px;
}