.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 9999999 !important;
  display: flex;
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.overlay {
  min-width: 88%;
  padding: 0 25px;
}

.contentBlock {
  padding: 24px;
  border-radius: 18px;
  background-color: var(--bg-modal);
  transition: 0.3s transform;
  transform: scale(0.5);
  z-index: var(--modal-z-index);
}

.closeBtn {
 text-align: center;
 color: var(--text-color-1);
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: var(--modal-z-index);

  .contentBlock {
      transform: scale(1);
  }
}

.isClosing {
  .contentBlock {
      transform: scale(0.2);
  }
}

.controlBtn {
  display: flex;
}