.payment_block {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - 197px);
	margin-left: -16px;

	h1 {
		font-family: "Roboto";
		font-size: var(--font-size-xl);
		line-height: var(--font-line-xl);
		letter-spacing: 0.1px;
		color: var(--primary-color);
		font-weight: var(--font-weight-bold);
		margin-top: 24px;
		margin-left: 16px;
		margin-bottom: 10px;
	}

	.payment_type_item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 70px;
		border-radius: 20px;
		cursor: pointer;

		.payment_single_item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(100vw - 32px);
			height: 70px;
			border-bottom: 1px solid var(--bg-input);

			.name_payment {
				display: flex;
				align-items: center;

				p {
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					letter-spacing: 0.15px;
					color: var(--primary-color);
					padding-left: 12px;
				}

				.icon_payment {
					path:first-child {
						fill: var(--primary-color);
					}

					path:nth-child(2) {
						stroke: var(--primary-color);
					}
				}
			}

			.paymentIcons {
				display: flex;
			}

			.trash_icon {
				path {
					fill: var(--primary-color);
				}
			}

			.check_icon {
				margin-left: 10px;

				path {
					stroke: var(--primary-color);
				}
			}
		}
	}
}

.confirm_payment {
	background: var(--bg-button-primary);
	display: flex;
	position: fixed;
	bottom: 0;
	margin-bottom: 40px;
	height: 56px;
	padding: 12px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 16px;
	margin-left: 16px;
	color: white;
	border: none;
	font-family: Roboto;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.5px;
	width: calc(100vw - 32px);
}

.continueBtn {
	margin-top: auto;
	padding-bottom: 40px;
}
