.DeleteAccountWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 90px);
}

.infoAboudDelete {
  margin-top: 20px;
  font: var(--font-ml);
  color: var(--primary-color);
  font-weight: var(--font-weight-light);
  letter-spacing: 0.5px;
}

.btnBlock {
  display: flex;
  justify-content: center;
  margin-top: auto;

  .confirmBtn {
    margin-bottom: 25px;
  }
}