.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.animationBlock {
  & .MuiCircularProgress-circle {
    stroke-dasharray: 25px, 200px;
  }
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoWord {
  position: absolute;
  top: 32%;
  left: 32%;

  .CarmaLogoWord {
    path {
      width: 23px;
      height: 24px;
      fill: var(--primary-color);
    }
  }
}
