.route_block {
    padding-top: 18px;

    .route_item_full {
        display: flex;
        flex-direction: row;
        height: 48px;
        border-radius: 16px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .input_block {
            display: flex;
            flex-direction: row;
            height: 48px;
            border-radius: 16px;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            justify-content: space-between;
            align-items: center;
            background: var(--bg-input);

            input {
                display: flex;
                padding: 0px;
                border: none;
                width: 100%;
                margin-left: 10px;
                font-family: "Roboto";
                font-size: var(--font-size-ml);
                line-height: var(--font-line-ml);
                letter-spacing: 0.15px;
                color: var(--primary-color);
                background: transparent;
                text-overflow: ellipsis;
            }

            input::placeholder {
                color: var(--secondary-color);
            }

            .location_icon {
                svg {
                    padding-top: 2px;
                }

                width: 24px;

                path {
                    fill: #1056BB;
                    stroke: #1056BB;
                }
            }
        }
    }

    .additional_class {
        margin-right: 0px !important;
    }

    .route_item_unfull {
        display: flex;
        flex-direction: row;
        height: 48px;
        border-radius: 16px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .input_block {
            display: flex;
            flex-direction: row;
            height: 48px;
            border-radius: 16px;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            justify-content: space-between;
            align-items: center;
            background: transparent;
            border: 1px solid var(--primary-color);

            input {
                display: flex;
                padding: 0px;
                border: none;
                width: 100%;
                margin-left: 10px;
                font-family: "Roboto";
                font-size: var(--font-size-ml);
                line-height: var(--font-line-ml);
                letter-spacing: 0.15px;
                color: var(--primary-color);
                background: transparent;
                text-overflow: ellipsis;
            }

            input::placeholder {
                color: var(--secondary-color);
            }

            .location_icon {
                svg {
                    padding-top: 2px;
                    width: 24px;

                    path {
                        fill: var(--primary-color);
                        stroke: var(--primary-color);
                    }
                }
            }
        }
    }

    .close_icon {
        display: flex;
        padding-left: 10px;

        path {
            stroke: var(--primary-color);
        }
    }

    .add_point {
        display: flex;
        min-width: 48px;
        height: 48px;
        background: #1056BB;
        border-radius: 16px;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        border: none;
    }

    .addresses {
        display: flex;
        flex-direction: column;
        margin-top: 8px;

        .first_address {
            padding-left: 10px;
            padding-right: 8px;
            padding-bottom: 10px;
            margin-top: 10px;
            border-bottom: 1px solid var(--bg-input);
        }

        .first_address:last-child {
            border-bottom: none;
        }

        .address_text {
            display: flex;
            flex-direction: row;
            align-items: center;

            .history_icon {

                path {
                    fill: var(--primary-color);
                }
            }

            .favorite_addresses_order {
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .main_text {
                    font-family: "Roboto";
                    font-size: var(--font-size-ml);
                    color: var(--primary-color);
                    letter-spacing: 0.5px;
                }

                .secondary_text {
                    font-family: "Roboto";
                    font-size: var(--font-size-s);
                    line-height: var(--font-line-s);
                    color: var(--text-color-2);
                    letter-spacing: 0.25px;
                    padding-top: 6px;
                }
            }
        }
    }

    .confirm {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;
        }

        .confirm_btn {
            background: var(--bg-button-primary);
            display: flex;
            height: 56px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 16px;
            color: white;
            border: none;
            font-family: "Roboto";
            font-size: var(--font-size-l);
            line-height: var(--font-line-l);
            font-weight: var(--font-weight-bold);
            letter-spacing: 0.5px;
            width: calc(100vw - 32px);
        }
    }
}

.disabled_btn {
    background: var(--bg-button-disabled) !important;
    color: var(--disabled-text-btn) !important;
    pointer-events: none;
}

.dots_icon {
    path {
        fill: var(--text-color-1);
    }
}


// .input.fullRemove {}
// .input {
//     &.fullRemove {}
// }
// .button.fullRemove {}
.user_geolocation {
    position: fixed;
    z-index: 1;
    top: calc(50% - 68px/2);
    left: calc(50% - 26px/2);
    -webkit-transform: perspective(1px) translateZ(0) translateY(-30px) translateX(-8px);
    transform: perspective(1px) translateZ(0) translateY(-30px) translateX(-8px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:before {
        pointer-events: none;
        position: absolute;
        z-index: -1;
        content: '';
        top: 100%;
        left: 5%;
        height: 10px;
        width: 90%;
        opacity: 0;
        background: -webkit-radial-gradient(center, ellipse, rgba(3, 3, 3, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0) 80%);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform, opacity;
        transition-property: transform, opacity;
    }

    &.animate {
        // -webkit-transform: translateY(-8px);
        // transform: translateY(1px);
        -webkit-transform: perspective(1px) translateZ(0) translateY(-33px) translateX(-8px);
        transform: perspective(1px) translateZ(0) translateY(-33px) translateX(-8px);

        &:before {
            opacity: 1;
            // -webkit-transform: translateY(5px);
            // transform: translateY(5px);
        }
    }

    img {
        width: 40px;
    }
}

.close_icon.fullRemoveX {
    display: none;
}

.close_icon.hiddenX {
    display: none;
}

.input_block.hiddenX {
    margin-right: 34px;
}

.identity_address_alert {
    font-family: "Roboto";
    color: #EE392E;
    padding-top: 5px;
    margin-bottom: 5px;
    font-size: var(--font-size-xs);
    padding-left: 45px;
}

.icon_users {
    width: 24px;

    path {
        fill: #1DB960 !important;
    }
}

.my_location_button {
    right: 16px;
    top: -70px;
    // margin-top: -70px;
    position: absolute;
    border-radius: 30%;
    border: none;
    background: var(--bg-color-primary);
    width: 43px;
    height: 43px;
    outline: none !important;

    svg {
        padding-top: 3px;
        width: 24px;
        height: 24px;

        path {
            fill: var(--primary-color);
        }
    }
}

@keyframes colorChange {
    0% {
        background: var(--keyframe-1);
    }
    20% {
        background: var(--keyframe-2);
    }
    40% {
        background: var(--keyframe-3);
    }
    60% {
        background: var(--keyframe-4);
    }
    80% {
        background: var(--keyframe-5);
    }
    100% {
        background: var(--keyframe-6);
    }
}

.animation_input {
    animation: colorChange 2s linear infinite;
}

.icon_green {
    svg {
        padding-top: 2px;
    }

    width: 24px;

    path {
        fill: #1DB960 !important;
        stroke: #1DB960 !important;
    }
}


.hiddenElem {
    display: none !important;
}

.display_none_class {
    display: none !important;
}