@import "../../style/variables";
/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
  //height: calc(100vh - 140px);
  //margin-top: 140px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-top: 0;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & a {
    display: none !important;
  }
}

#mode-selector {
  color: #fff;
  background-color: #4d90fe;
  margin-left: 12px;
  padding: 5px 11px 0px 11px;
}

#mode-selector label {
  font-size: 13px;
  font-weight: 300;
}

.gmnoprint {
  display: none;
}

.map-center {
  position: absolute;
  bottom: 14px;
  left: 12px;
  color: $primary-color-1;
  width: 30px;
  text-align: center;
}

.pac-container {
  position: absolute;
  top: 0;
  border-radius: 0 0 12px 12px;
  z-index: 10000 !important;
  &:after {
    display: none !important;
  }
}

.pac-item {
  padding: 5px 11px !important;
}
