.NewsWrapper {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.contentNews {
  padding: 16px 24px 24px 24px;
  background-color: var(--bg-trip);
  border-radius: 28px;
  margin-bottom: 16px;

  .dateInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
    p {
      font-family: 'Roboto';
      margin-top: 3px;
      font-size: 12px;
      font-weight: var(--font-weight-light);
      color: var(--text-color-2);
      letter-spacing: 0.4px;
    }

    .infoBtn {
      background-color: #FFDE17;
      padding: 3px 12px 4px 12px;
      border-radius: 100px;
      font-size: 12px;
      font-family: 'Roboto';
      color: #1F2D3E;
      letter-spacing: 0.4px;
    }
  }

  .title {
    font: var(--font-l);
    color: var(--text-color-6);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.5px;
    border-bottom: 1px solid var(--bg-modal);
    padding-bottom: 8px;
  }

  .subTitle {
    padding-top: 8px;
    font: var(--font-ml);
    color: var(--text-color-1);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.5px;
  }
}