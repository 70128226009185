@import "../../style/variables";

.rating {
  & .MuiRating-root {
    color: $primary-color-1;
  }

  &__text {
    color: $secondary-color-black;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 44px;
  }

  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
    font-size: 45px;
  }
}
