.ErrorPageWrapper {
  background: var(--bg-color-primary);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    h1 {
      font: var(--font-xl);
      color: var(--primary-color);
      font-weight: var(--font-weight-dark);
      letter-spacing: 0.5px;
      font-size: 33px;
      padding-bottom: 5px;
    }

    h4 {
      font: var(--font-l);
      color: var(--secondary-color);
      font-weight: var(--font-weight-regular);
      letter-spacing: 0.5px;
      padding-bottom: 15px;
    }

    .accordionError {
      margin: 0 20px;
    }
  }

  .errorBtnBlock {
    margin-top: auto;
    width: 90%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
}
