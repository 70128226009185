@import '../../style/variables';
@import '../../app/styles/variables/global.scss';

.order-submit {
  background: $primary-color-1;
  color: $secondary-color-white;
  padding: 10px;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.order-footer {
  position: absolute;
  bottom: 25px;
  left: 16px;
  right: 16px;
}

.order-sum {
  padding: 8px 10px;
  background: $secondary-color-white;
  border-radius: 12px;
  margin: 5px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

  & .row{
    align-items: center;
  }

  & .col-4 {
    display: flex;
    align-items: center;

    .decrease-price_icon {
      color: #FF6347;
    }

    .increase-price_icon {
      color: #3BB525;
    }
  }

  &__price {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    padding: 3px 0 6px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.87);

    &--border {
      border-bottom: 1px solid #F7F7F7;
    }
  }

  &__old-price {
    font-size: 14px;
    display: block;
    text-decoration: line-through;
    color: $primary-text-color;
  }

  &__options {
    position: relative;
    padding: 10px 0 5px;

    & .col-4 {
      position: relative;
    }

    &-icon {
      position: absolute;
      width: 20px;

      & img {
        max-width: 100%;
      }
    }

    &-text {
      margin-left: 25px;
    }

    &-label {
      color: #8E8E91;
      line-height: 12px;
      font-size: 12px;
      margin-bottom: 4px;
    }

    &-item {
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
    }
  }
}

.order-cars {
  margin: 0 -5px!important;

  &__item {
    padding: 4px 10px!important;
    background: $secondary-color-white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 0 3px;
    border: 2px solid #ffffff;
    max-width: calc(33.33333% - 8px)!important;

    &.active {
      border-color: $primary-color-1;
    }
  }

  &__header {
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  &__img {
    text-align: center;
  }

  &__price {
    color: #B3B3B3;
    font-size: 14px;
    margin-top: 6px;
  }
}

.order-find-car {
  border-bottom: 1px solid #F7F7F7;

  &__header {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 42px;
  }

  &__cancel {
    color: $primary-color-3;
    line-height: 40px;
    text-align: right;
    position: relative;
    z-index: 999;
  }

  & img {
    margin-top: -5px;
  }
}

.order-driver {
  margin-bottom: 8px;

  & .col-3 {
    height: 60px;
    border-radius: 50%;
    width: 60px;
    overflow: hidden;
    flex: 0 0 60px;
    max-width: 60px;

    & svg {
      height: 100%!important;
      width: 100%!important;
    }
  }

  &__photo {
    max-width: 100%;

    & img {
      max-width: 100%;
    }
  }

  &__text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 5px;
  }

  &__phone {
    display: block;
    margin: 0 auto;
    text-align: right;
    padding: 18px 0;
  }
}

.popup-message {
  text-align: center;
  display: block;
  font-size: 18px;
  margin: 6px 0;
}

.preferences {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  line-height: 28px;
  border-bottom: 1px solid #F7F7F7;
  padding: 10px 0;

  &__price {
    color: #8E8E91;
    opacity: 0.8;
    margin-left: 10px;
  }

  &:first-child {
    margin-top: 20px;
    border-top: 1px solid #F7F7F7;
  }

  & .col-2 {
    color: $primary-color-1;
  }
}

.order-bonus {
  text-align: right;
  font-size: 18px;

  & .PrivateSwitchBase-root-28 {
    padding: 3px!important;
    vertical-align: -5px!important;
  }

  &.active {
    color: $primary-color-1;
  }
}

.border {
  background:  #F7F7F7;
  height: 1px;
}

.favorite-address {
  position: absolute;
  z-index: 999;
  width: calc(100% - 32px);
  margin: 0 16px;
  border-radius: 0 0 12px 12px;
  //height: 211px;
  background: #FFFFFF;

  &.origin {
    top: 126px;
  }
  &.destination {
    top: 168px;
  }

  &__item {
    cursor: default;
    padding: 5px 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999;

    &.hide {
      display: none!important;
    }

    & svg {
      vertical-align: -6px;
    }
  }

  &__exit {
    float: right;
    padding: 0 10px 10px;
    font-size: 12px;
    color: $primary-color-3
  }
}

.order-comment {
  width: 100%;
  padding: 4px 3px 0;
  border: none;

  &-line {
    width: 100%;
    height: 1px;
    border-top: 1px solid #f4f4f4;
    margin: 12px 0 0;
  }
}

.finish-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}