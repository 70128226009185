.imageUser {
  position: relative;
  .circleAvatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .imageBlock {
    height: 64px;
    width: 64px;
    overflow: hidden;
    background-color: #1056BB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;


    .icon {
      stroke: var(--bg-color-primary);
    }
  }

  .noImg {
    align-items: center;
  }
}