.AuthenticationWrapper {
  background-color: #1056BB;
  min-height: 100vh;
  padding: var(--padding-horizontal);
  display: flex;
  flex-direction: column;
}

.logoImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding-top: 24px;
  gap: 23px;
}

.btnAuthentication {
  padding-top: 24px;
  margin-top: auto;
  margin-bottom: 21px;

  .btn {
    margin-bottom: 5px;
    text-align: center;
    background-color: #212121;
  }
}