.windowWrapper {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    background: var(--bg-color-primary);
    border-radius: 28px 28px 0 0;
    padding: 0px 16px 30px 16px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

    .downLine {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 18px;
    }

    button {
        display: flex;
        align-items: center;
        flex-direction: row;
        background: transparent;
        border: 1px solid var(--border-color-1);
        border-radius: 16px;
        height: 48px;
        width: 100%;
        background: var(--bg-input);
        border-radius: 16px;
        border: none;
        margin-bottom: 8px;
        gap: 8px;

        svg {
            padding-left: 7px;
        }

        path {
            stroke: var(--primary-color);
        }

        p {
            font: var(--font-ml);
            color: var(--primary-color);
            font-weight: 700;
            letter-spacing: 0.5px;
        }
    }
}

.singleAddressText {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    border-bottom: 1px solid var(--bg-input);
    padding: 8px 0;

    svg {
        padding-left: 12px;
    }

    path {
        fill: var(--primary-color);
    }

    &:last-child {
        border-bottom: none;
    }
}

.textBlock {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    width: calc(100% - 60px);

    .mainText {
        font: var(--font-s);
        color: var(--primary-color);
        letter-spacing: 0.15px;
    }

    .secondaryText {
        font: var(--font-s);
        color: var(--text-color-2);
        letter-spacing: 0.15px;
    }
}