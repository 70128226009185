.ratingIcons {
    display: flex;
    align-items: center;
    padding-top: 16px;
}

.icon {
    cursor: pointer;
    margin-right: 10px;
}

.icon:last-child {
    margin-right: 0px;
}

.active {
    path {
        fill: #FFDE17;
    }
}