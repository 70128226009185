.HistoryDetailItem {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
  padding-top: 18px;
}

.help {
  h1 {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1px;
    margin-top: 26px;
  }

  .link {
    margin: 0;
    border: none;
  }
}

