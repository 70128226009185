.ApkRulesWrapper {
  padding-top: 17px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.title {
  font: var(--font-ml);
  color: var(--primary-color);
  font-weight: var(--font-weight-light);
  margin-bottom: 30px;
}