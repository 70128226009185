.dialog-wrapper {
  & .MuiDialogContent-root {
    padding: 20px 0 0 0;
  }
}

.DialogHeader {
  z-index: 1 !important;
}

.PageLayout {
  z-index: 2;
}

.small_header {
  min-height: 50px;
}

.small_layout {
  margin-top: 50px;
}
