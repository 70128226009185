.SmsInput {
  display: flex;
  justify-content: center;

  input {
    text-align: center;
    padding: 14px 0;
    width: 60px;
    border: none;
    outline: none;
    margin: 0 6px;
    background: inherit;
    border-bottom: 2px solid var(--primary-color);
    font: var(--font-xl);
    color: var(--primary-color);
  }
}