.ChangePassword {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 110px);
  padding-top: 20px;
}


.newPassword {
   margin-top: 20px;
}

.btnBlock {
  display: flex;
  justify-content: center;
  margin-top: auto;

  .saveBtn {
    margin-bottom: 25px;
  }
}