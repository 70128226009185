.invoices {
  display: flex;
  flex-direction: column;
  padding-top: 23px;

  p {
    font-family: "Roboto";
    font-size: var(--font-size-ml);
    line-height: var(--font-line-ml);
    letter-spacing: 0.15px;
    color: var(--primary-color);
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.invoice {
  display: flex;
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid var(--bg-input);
}