:root {
  --font-family-main: "Roboto";

  // Жирность шрифта
  --font-weight-light: 200;
  --font-weight-semilight: 400;
  --font-weight-regular: 500;
  --font-weight-bold: 700;

  // For error
  --font-size-error: 11px;
  --font-line-error: 15px;
  --font-error: var(--font-size-error) / var(--font-line-error) var(--font-family-main);

  // xs
  --font-size-xs: 12px;
  --font-line-xs: 16px;
  --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);

  // s
  --font-size-s: 14px;
  --font-line-s: 20px;
  --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  // m
  --font-size-m: 15px;
  --font-line-m: 21px;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

  // ml
  --font-size-ml: 16px;
  --font-line-ml: 24px;
  --font-ml: var(--font-size-ml) / var(--font-line-ml) var(--font-family-main);

  // l
  --font-size-l: 18px;
  --font-line-l: 24px;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  // xl
  --font-size-xl: 24px;
  --font-line-xl: 30px;
  --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

  // Отсупы
  --padding-horizontal: 0 17px;
  --padding-vertical: 40px 0;

  // Индексы
  --modal-z-index: 1000;
  --overlay-z-index: 100;

  // Размеры картинок, иконок
  --size-icon: 16px;
}
