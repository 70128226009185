.AuthPasswordWrapper {
  padding: var(--padding-vertical);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.title {
  padding-bottom: 20px;
  font: var(--font-xl);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.continueBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.forgot {
  text-decoration: none;
  text-align: left;
  font: var(--font-s);
  font-weight: var(--font-weight-light);
  color: var(--secondary-color);
  padding-left: 10px;
  margin-top: 15px;
}