.DetailNewsWrapper {
  padding: 18px 0;
  .date {
    font: var(--font-s);
    color: var(--text-color-2);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.4px;
    margin-bottom: 16px;
  }

  .title {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    line-height: 30px;
    letter-spacing: 0.1px;
    border-bottom: 1px solid var(--border-color-3);
    padding-bottom: 18px;
  }

  .contentInfo {
    padding-top: 18px;
    p {
      margin-bottom: 20px;
      font: var(--font-ml);
      color: var(--primary-color);
      font-weight: var(--font-weight-light);
      letter-spacing: 0.5px;
    }
  }
}
