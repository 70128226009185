.price_container {
	display: flex;
	width: calc(100vw - 32px);
	height: 56px;
	align-items: center;
	justify-content: flex-end;

	.description {
		display: flex;
		align-items: center;
		gap: 8px;

		.titleBlock {
			display: flex;
			flex-direction: column;

			p {
				color: var(--primary-color);
				font-family: Roboto;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0.15px;
			}
		}
	}
}

.price_container_multi_items {
	justify-content: space-between;
}

.arrowIcons {
	path {
		fill: var(--high-demand-color);
	}
}

.price_changes_block {
	display: flex;
	align-items: center;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;

		.active_sign {
			font-size: 24px;
			color: var(--primary-color);
			font-style: normal;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: 0.1px;
			margin-left: 2px;
		}

		.active_sign_minus {
			font-size: 24px;
			color: var(--primary-color);
			font-style: normal;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: 0.1px;
			margin-right: 2px;
		}

		.disabled_sign {
			color: var(--text-color-2) !important;
		}
	}

	.price_change_cost {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.price_item {
			display: flex;
			gap: 2px;
		}

		.priceWithoutBonusBlock {
			display: flex;
			gap: 2px;

			p {
				color: #9e9e9e;
				font-size: 16px;
				font-weight: 500;
			}
		}

		.priceWithoutBonus {
			text-decoration: line-through;
		}

		.currentPriceBlock {
			display: flex;
			gap: 2px;
		}

		p {
			font-family: "Roboto";
			font-size: 24px;
			font-weight: var(--font-weight-bold);
			color: var(--primary-color);
			letter-spacing: 0.1px;
			padding-left: 4px;
		}
	}
}

.checkboxBonus {
	display: flex;
	position: relative;
	align-items: center;

	.question_icon {
		position: absolute;
		left: 0;
		top: 0;
		margin-left: 96px;
		margin-top: -5px;

		svg {
			width: 16px;
			height: 16px;
		}

		.questionBlock {
			display: flex;
			width: 131px;
			padding: 16px;
			margin-left: 10px;
			background: var(--inverted-primary-color);
			align-items: center;
			justify-content: center;
			text-align: center;
			border-radius: 0px 16px 16px 16px;
			box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

			p {
				font-size: 12px;
				font-family: "Roboto";
				color: var(--primary-color);
				line-height: 18px;
				letter-spacing: 0.4px;
			}

			@media (max-width: 300px) {
				width: 100px;
			}
		}
	}

	span {
		font: var(--font-s);
		color: var(--primary-color);
	}
}

.services_block {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0 24px 0;

	a {
		text-decoration: none;
		outline: none;
	}

	.preferences_btn {
		display: flex;
		background: transparent;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2px;

		svg {
			width: 24px;
			height: 24px;
		}

		path {
			fill: var(--primary-color);
		}

		p {
			font-family: "Roboto";
			font-size: 12px;
			color: var(--primary-color);
			letter-spacing: 0.1px;
		}
	}

	.payment_btn {
		display: flex;
		background: transparent;
		align-items: center;
		justify-content: center;

		.name_payment {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 2px;

			svg {
				width: 24px;
				height: 24px;
			}

			p {
				font-family: "Roboto";
				font-size: 12px;
				color: var(--primary-color);
				letter-spacing: 0.1px;
			}
		}
	}

	.cashback_block {
		display: flex;
		flex-direction: column;
		gap: 6px;

		.titleCashback {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 4px;

			.check_icon {
				path {
					stroke: var(--primary-color);
				}
			}

			.cashback_icon {
				path {
					fill: var(--primary-color);
				}
			}

			p {
				font-family: "Roboto";
				font-size: var(--font-size-ml);
				font-weight: var(--font-weight-regular);
				line-height: var(--font-line-ml);
				letter-spacing: 0.15px;
				color: var(--primary-color);
			}
		}

		.count_cashback {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 4px;

			p {
				font-family: "Roboto";
				font-size: 12px;
				font-weight: var(--font-weight-regular);
				color: var(--text-color-12);
				letter-spacing: 0.1px;
			}
		}
	}
}

.cashbackBlockDisabled {
	opacity: 0.6;
	pointer-events: none;
}

.cashbackBlockDisabledDark {
	color: var(--text-color-2);
	pointer-events: none;
}

.titleCashbackDisabled {
	opacity: 0.7;
}

.titleCashbackDisabledDark {
	p {
		color: var(--text-color-2) !important;
	}

	.cashback_icon {
		path {
			fill: var(--text-color-2) !important;
		}
	}
}
