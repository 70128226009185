
.totalPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--secondary-border);
  margin-top: 35px;
  padding-bottom: 15px;

  h1 {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1px;
  }

  p {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    line-height: 30px;
    letter-spacing: 0.1px;
  }

  .currency {
    font-size: 22px;
  }
 }


 .listPrice {
  padding-top: 15px;
  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font: var(--font-ml);
    color: var(--primary-color);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.5px;
  }

  .itemDiscount {
    color: #1056BB;
    .amount {
      color: #1056BB;
    }
  }

  .label {
    margin-right: 10px;
  }

  .amount {
    font: var(--font-l);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.5px;

    .currency {
      font-size: 17px;
    }
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .leftContent {
    display: flex;
    align-items: center;
    position: relative;
    .dot{
      font-weight: var(--font-weight-bold);
      font-size: 29px;
      position: absolute;
      bottom: 7px;
    }

    .dotTimeContainer {
      margin-left: 15px;
      .amount {
        font: var(--font-l);
        color: var(--primary-color);
        font-weight: var(--font-weight-bold);
        letter-spacing: 0.5px;
      }
    }
  }

  .rightContent {
    text-align: right;
  }
}

