.ActionModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titleInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: 8px;
  }

  p {
    font: var(--font-m);
    color: var(--secondary-color);
    font-weight: var(--font-weight-light);
  }
}

.actionBlock {
  min-width: 80%;
}

.actionsContainer {
  margin-top: 20px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.noSubtitle {
  margin-top: 14px;
}

.action {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--primary-border);
  padding: 18px 0;
  margin-left: -19px;

  p {
    margin-left: 19px;
    font: var(--font-ml);
    color: var(--primary-color);
    font-weight: var(--font-weight-light);
  }
}