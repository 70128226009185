@import '../../style/variables';

.MuiFormLabel-root.Mui-focused {
  color: $primary-color-1!important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid $primary-color-1!important;
}
.PrivateNotchedOutline-root-58 {
  border-radius: 8px!important;
}

.MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #f44336!important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary-color-1!important;
}