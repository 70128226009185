.HistoryWrapper {
  padding: 23px 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.historyItemBlock {
  .dateBlock {
    display: flex;
    justify-content: space-between;

    h3 {
      margin: 0 auto;
      text-align: center;
      font: var(--font-s);
      color: var(--secondary-color);
      font-weight: var(--font-weight-light);
      line-height: 20px;
      letter-spacing: 0.25px;
      padding-top: 20px;
      padding-bottom: 9px;
    }

    .sortIcon {
      fill: var(--text-color-4);
    }
  }

  &:first-child {
    h3 {
      margin-bottom: 23px;
      padding: 0;
    }
  }
}

