
/* .HistoryDetailHeader {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
} */
.dateTrip {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;

  h3 {
    text-align: center;
    font: var(--font-s);
    color: var(--secondary-color);
    font-weight: var(--font-weight-light);
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}

.infoDriverBlock {
  background-color: var(--bg-menu);
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px 22px 16px;

  h1 {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1px;
    padding-bottom: 8px;
  }
  h2 {
    font: var(--font-ml);
    color: var(--secondary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1px;
    padding-bottom: 3px;
  }

  .infoCar {
    display: flex;
    align-items: center;
    .tariffCar {
      font: var(--font-ml);
      color: var(--secondary-color);
      font-weight: var(--font-weight-light);
      letter-spacing: 0.5px;
      padding-right: 10px;
    }
    .numberCar {
      font: var(--font-error);
      font-size: 13px;
      background-color: #FFDE17;
      padding: 0 9px;
      border-radius: 6px;
      color: var(--text-color-3);
      font-weight: var(--font-weight-light);
      letter-spacing: 0.4px;
      line-height: 21px;
    }
  }

  .photoDriver {
    position: relative;
    .rate {
      min-width: 52px;
      top: 49px;
      left: 6px;
      position: absolute;
      background-color: #fff;
      border-radius: 100px;

      .rateInfo {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .starIcon {
        /* padding-left: 4px; */
        width: 14px;
        height: 16px;
      }

      span {
        padding: 0 3px;
        font: var(--font-m);
        font-weight: var(--font-weight-regular);
        color: #212121;
      }
    }
  }
}


.failureTrip {
  background-color: var(--bg-button-disabled);
  color: var(--inverted-primary-color);
}