@import '../../style/variables';

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: $secondary-color-white;

  &__back {
    background: $primary-color-1;
    border-radius: 50%;
    padding: 5px;
    color: $secondary-color-white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    height: 32px;
    width: 32px;

    & svg {
      vertical-align: -5px;
      font-size: 26px;
    }
  }

  & h1 {
    text-transform: uppercase;
    line-height: 25px;
    color: $secondary-color-black;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-left: -15px;
  }

  & path {
    fill: $secondary-color-white;
  }

  &__border {
    background: #F7F7F7;
    width: 100%;
    height: 2px;
    margin: 20px auto 12px;
  }
}

.with-header {
  margin-top: 5.5em;
}