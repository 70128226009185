.MenuHeaderWrap {
  background-color: var(--bg-menu);
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 52px 15px 0 17px;

  h2 {
    font: var(--font-l);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .link {
    padding-bottom: 28px;
    font: var(--font-s);
    font-weight: var(--font-weight-light);
    text-decoration: none;
    color: #1056BB;
  }
}

.imageUser {
  position: relative;
  margin-top: 22px;
  margin-bottom: 10px;

  .rate {
    min-width: 52px;
    top: 49px;
    left: 6px;
    position: absolute;
    background-color: #fff;
    border-radius: 100px;

    .rateInfo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .starIcon {
      // padding-left: 4px;
      width: 17px;
      height: 16px;
    }

    span {
      font: var(--font-m);
      font-weight: var(--font-weight-regular);
      color: #212121;
    }
  }
}

