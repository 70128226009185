
.routeContainer {
  h1 {
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1px;
    margin-top: 40px;
    margin-bottom: 22px;
  }
}

