.InputWrapper {
  position: relative;
}

.input {
  outline: none;
  text-indent: 10px;
  border: none;
  width: 100%;
  border-radius: 16px;
  background-color: var(--bg-input);
  padding: 13px 0 11px 0;
  font: var(--font-ml);
  font-weight: var(--font-weight-light);
  color: var(--primary-color);
  letter-spacing: 0.15px;

  &::placeholder {
    font: var(--font-ml);
    font-weight: var(--font-weight-light);
    color: var(--secondary-color);
  }
}

.disable {
  background-color: var(--bg-button-disabled);
  color: var(--disabled-text-btn)
}

.error {
  font: var(--font-error);
  font-weight: var(--font-weight-light);
  color: #ff4d4f;
  position: absolute;
  left: 10px;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
      height: 20px;

       path {
        stroke: var(--primary-color);
      }
    }
}