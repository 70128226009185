[data-theme="dark"] {
  --bg-color-primary: #0D0D0D;
  --bg-color-secondary: #1056BB;
  --bg-color-modal: #2D4053;

  // Цвет текста
  --primary-color: #ffffff;
  --inverted-primary-color: #212121;
  --secondary-color: #8391a1;
  --text-color-1: #8391a1;
  --text-color-2: #414042;
  --text-color-3: #1f2d3e;
  --text-color-4: #ffffff;
  --text-color-5: #8391a1;
  --text-color-6: #ffffff;
  --text-color-7: #8391a1;
  --text-color-8: #ffffff;
  --text-color-9: #616161;
  --text-color-10: #ffffff;
  --text-color-11: #616161;
  --text-color-12: #414042;
  --text-color-13: #616161;
  --text-color-14: #A8A8A8;

  // Цвет кнопок
  --bg-button-primary: #171A1F;
  --bg-button-secondary: #FF1940;
  --bg-button-disabled: #171A1F;
  --disabled-text-btn: #A8A8A8;

  // Menu
  --bg-menu: #233f5b;

  // Border
  --primary-border: #8391a1;
  --secondary-border: #2d4053;
  --border-color-1: #657180;
  --border-trip: #314563;
  --border-route: #8391a1;
  --border-color-2: #657180;
  --border-color-3: #313d66;
  --border-color-4: #2d4053;
  --border-color-5: #212121;

  // Trip
  --bg-trip: #2f4153;
  --bg-fail-trip: #2d4053;

  // Icon color
  --primary-icon: #A8A8A8;

  // Input
  --bg-input: #212121;

  // Modal
  --bg-modal: #0D0D0D;

  // Switch
  --bg-select: #1a3e62;

  // Search car loader
  --bg-loader: #2f4153;

  // Drivers background
  --bg-drivers-block: #233f5b;

  // Review block background
  --bg-review: #1f2f3e;
  --bg-textarea: #657180;
  --placeholder: #A8A8A8;

  // colors for animation markers on map
  --shadow-color: rgba(254, 254, 254, 0.3);

  // colors for inputs keyframes animation
  --keyframe-1: #2d4053;
  --keyframe-2: #384b61;
  --keyframe-3: #3e546c;
  --keyframe-4: #3e546c;
  --keyframe-5: #384b61;
  --keyframe-6: #2d4053;

  // gps colors
  --gps-color-active: #1056BB;

  // discount block
  --linear-gradient-blue: linear-gradient(to right, #2B99FF, #3B42F0, #5854FF);

  // main blue color
  --dark-blue-color: #1056BB;

  // header bg
  --wrapper-bg: #171A1F;
}