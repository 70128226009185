.maplibre-map {
  width: 100%;
  height: 100%;

  .maplibregl-ctrl-top-left {
    top: 150px;
    left: 6px;
  }
}

.Loader {
  height: 100%;
}

.location-button,
.speed {
  position: absolute;
  z-index: 2;
  background-color: var(--disabled-text-btn);
  border-radius: 16px;
  border: none;
  outline: none;
}

.location-button {
  position: absolute;
  bottom: 65px;
  right: 16px;
  border-radius: 30%;
  border: none;
  background: var(--bg-color-primary);
  width: 44px;
  height: 44px;
  color: black;
  outline: none !important;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
}

.location-button-top {
  bottom: 70px;
  transition: bottom 0.3s ease;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
}

// .location-button-tracking {
//   .location-image {
//     fill: var(--gps-color-active);
//     stroke: var(--gps-color-active);

//     path {
//       fill: var(--gps-color-active);
//       stroke: var(--gps-color-active);
//     }
//   }
// }

.locationIcon {
  width: 24px;
  height: 24px;
  transform: translate(38%, 42%);
  
  path {
    fill: var(--primary-color);
  }
}
// .speed {
//   display: flex;
//   flex-direction: column;
//   font-weight: 700;
//   font-size: 16px;
//   width: 64px;
//   height: 64px;
//   z-index: 9999;
//   position: absolute;
//   bottom: 26px;
//   left: 16px;
//   justify-content: center;
//   align-items: center;
//   color: var(--inverted-bg-color);;
// }

.car-marker,
.client-marker,
.pickup-point-marker {
  background-repeat: no-repeat;
  svg {
    display: none;
  }
}

.car-marker {
  background-image: url("../../images/car-marker.svg");
  background-size: contain;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.client-marker {
  background-image: url("../../images/userLocation.svg");
  background-size: contain;
  width: 70px;
  height: 70px;
  z-index: 1;
}

.pickup-point-marker {
  background-image: url("../../images/map-icons/point.svg");
  background-size: contain;

  width: 33px;
  height: 40px;
}

.blackPoint,
.grayPoint {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pointBig {
  width: 32px;
  height: 32px;
}

.innerBlackPoint {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerPointBig {
  width: 18px;
  height: 18px;
}

.blackPoint {
  background-color: var(--primary-color);

  .innerBlackPoint {
    background-color: var(--inverted-primary-color);
    color: var(--primary-color);
  }
}