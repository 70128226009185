.profile {
  background-color: var(--bg-menu);
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 22px;
  margin-bottom: 10px;

  .icon {
    stroke: var(--inverted-primary-color);
  }

  h2 {
    font: var(--font-l);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .phone {
    font: var(--font-ml);
    font-weight: var(--font-weight-light);
    color: var(--secondary-color);
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
  }

  .link {
    padding-bottom: 24px;
    font: var(--font-s);
    font-weight: var(--font-weight-light);
    text-decoration: none;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #1056BB;
  }
}





