.ChatWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  min-height: calc(100vh - 92px);
}

.contentBlock {
  padding: var(--padding-horizontal);
  margin-bottom: 85px;
}

.helpDateBlock {
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    margin: 26px 0;
    text-align: center;
    font: var(--font-s);
    color: var(--secondary-color);
    font-weight: var(--font-weight-light);
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .chatItem {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 21px;
    padding: 12px 16px;
    border-radius: 22px;
    font: var(--font-s);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.403px;

    &.chatAnswer {
      align-self: flex-start;
      background-color: #eeeeee;
      font: var(--font-s);
      font-size: 13px;
      color: #212121;
      line-height: normal;
      font-weight: var(--font-weight-light);
      letter-spacing: 0.403px;
      width: 100%;
    }

    &.chatUser {
      width: 100%;
      align-self: flex-end;
      background-color: #1056BB;
      font: var(--font-s);
      color: #ffffff;
      line-height: normal;
      font-weight: var(--font-weight-light);
      letter-spacing: 0.403px;
    }

    .chatContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .message {
        order: 1;
      }

      .timeMessage {
        order: 2;
        align-self: flex-end;
        font: var(--font-s);
        font-size: 10px;
        font-weight: var(--font-weight-light);
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    &:last-child {
      margin: 0 0 0 0;
    }
  }
}

.sendMessageBlock {
  padding: 6px 0 16px 0;
  margin-top: 20px;
  background: #f0f0f0;
  width: 100%;
  position: fixed;
  bottom: 0;

  .messageField {
    text-indent: 10px;
    background-color: inherit;
    border: 1.5px solid #0682f2;
    border-radius: 16px;
    /* margin: 0 10px; */
  }
}


.noHits {
  display: flex;
  justify-content: center;
  font: var(--font-xl);
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
}