.RegistrationWrapper {
  padding: var(--padding-vertical);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.title {
  padding-bottom: 20px;
  font: var(--font-xl);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.inputPhone {
  display: flex;
  padding-bottom: 6px;
  .phone {
    flex: 1;
    margin-left: 3px;
  }
}

.field {
  margin: 12px 0 8px 0;
}

.requiredInputs {
  padding-left: 5px;
  padding-top: 7px;
  p {
    font: var(--font-s);
    font-weight: var(--font-weight-light);
    color: var(--secondary-color);
  }
}

.terms {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;

  p {
    font: var(--font-s);
    font-weight: var(--font-weight-light);
    color: var(--secondary-color);
    padding-right: 30px;

    .link {
      color: inherit;
    }
  }
}

.continueBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 60px;
}

.languages {
  display: flex;
  justify-content: center;
  margin-top: auto;
  .btnLanguages {
    padding: 0;
    align-items: center;
    border-radius: 8px;

    .icon {
      padding-left: 17px;
      stroke: var(--primary-icon);
    }

    .value {
      font: var(--font-m);
      font-weight: var(--font-weight-light);
      padding: 6px 16px 6px 10px;
    }
  }
}
