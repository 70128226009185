.AddressStripHistoryWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .placeContent {
    display: flex;
    align-items: flex-start;
    padding-bottom: 10px;

    .waypointIcon,
    .locationIcon {
      position: absolute;
      circle {
        fill: var(--bg-trip);
      }
    }

    .waypointIcon {
      left: 0.5px;
      top: 2px;

      circle {
        fill: var(--bg-trip);
      }
    }

    .place {
      border-bottom: 1px solid var(--secondary-border);
      width: 100%;
      padding-bottom: 8px;
      margin-left: 40px;
      letter-spacing: 0.5px;
      font: var(--font-ml);
      color: var(--primary-color);
      font-weight: var(--font-weight-regular);
    }
  }

  &:last-child {
    padding-bottom: 0;
    border: none;

    .place {
      border-bottom: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: var(--border-route);
    bottom: -12px;
    left: 10.5px;
  }

  &:not(:last-child)::before {
    min-height: 100%;
  }
}

// Доп. стили для странички HistoryDetailRoutes
.detailHistory {
  &::before {
    background-color: var(--border-color-3);
  }
  .placeContent {
    padding-bottom: 18px;
    .place {
      padding-bottom: 18px;
      font-weight: var(--font-weight-light);
     }
  }
}

// Если statusId === 2
.failureTrip {
  .placeContent {
    .locationIcon {
      path {
        fill: var(--bg-button-disabled);
        stroke: var(--bg-button-disabled);
      }
    }
    .waypointIcon {
      fill: var(--bg-trip);
      rect {
        stroke: var(--bg-button-disabled);
      }
    }
    .place {
      color: var(--bg-button-disabled);
    }
  }
}