.menuLink {
	color: var(--primary-color);
	font: var(--font-ml);
	font-weight: var(--font-weight-light);
	text-decoration: none;
	border-bottom: 1px solid var(--secondary-border);
	display: flex;
	align-items: center;
	padding: 24px 0 22px 0;
	margin: var(--padding-horizontal);
	column-gap: 10px;

	&:last-child {
		border: none;
	}
}

.menuIcon {
	fill: var(--primary-color);
	width: 24px;
	height: 24px;

	circle {
		stroke: var(--primary-color);
		fill: none;
	}

	path {
		fill: var(--primary-color);
	}
}

.menuText {
	flex: 1 1 auto;
	line-height: normal;
	letter-spacing: 0.5px;
	font: var(--font-ml);
	font-weight: var(--font-weight-light);
	color: var(--primary-color);
}

.menuAction {
	display: flex;
	align-items: center;

	.badge {
		margin-right: 5px;
	}

	.rightIcon {
		stroke: var(--primary-color);
		stroke-width: 2.2px;
	}
}
