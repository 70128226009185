.AlertModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titleInfo {
  h1 {
    text-align: center;
    font: var(--font-xl);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: 14px;
  }

  p {
    color: var(--primary-color);
    font: var(--font-m);
    letter-spacing: .5px;
    text-align: center;
    margin-bottom: 24px;
  }
}