.ProfileWrap {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.blockInfo {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-top: 8px;

}

.mainMenu {
  margin: 20px 0;

  .menuItemLink {
    margin: 0;
  }

  .menuItemBtn {
    padding-top: 24px;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--secondary-border);

    &:last-child{
      border: none;
    }
  }
}

.secondaryMenu {
  h1 {
    font: var(--font-xl);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    margin-bottom: 20px;
  }

  .menuItemBtn {
    margin-top: 20px;
    padding: 24px 0 22px 0;
    border-bottom: 1px solid var(--secondary-border);
  }
}

.icon {
  fill: var(--primary-color);
}

// PopUp
.popupMessage {
  font: var(--font-xl);
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
}