.footer {
  padding: var(--padding-horizontal);
  margin-top: auto;
  margin-bottom: 20px;
  padding-top: 20px;
}

.footer_info {
  display: flex;
}

.blockInfo {
  margin: 0 5px 20px 0;
}

.btn {
  background-color: #1DB960;
  margin-top: auto;
  padding: 0;
  a {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    text-decoration: none;
    font: var(--font-l);
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #fff;
  }
}

.version {
  margin-top: 14px;
  font: var(--font-s);
  font-weight: var(--font-weight-light);
  color: var(--text-color-2);
}