.OrderPreferencesWrapper {
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
}

.preferences_block {
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
    }

    .preferences_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 54px;
        margin-top: 16px;

        .comment_block {
            display: flex;
            align-items: center;
            justify-content: center;

            .comment_icon {
                svg {
                    padding-top: 2px;
                }

                path {
                    fill: var(--primary-color);
                    stroke: var(--primary-color);
                }
            }

            p {
                font-family: "Roboto";
                font-size: var(--font-size-ml);
                line-height: var(--font-line-ml);
                margin-left: 12px;
                letter-spacing: 0.15px;
                color: var(--primary-color);
                font-weight: 400 !important;
            }
        }

        .chevron_right {
            display: flex;

            svg {
                padding-top: 2px;
                margin-right: -4px;
            }

            path {
                stroke: var(--primary-color);
            }
        }
    }

    h1 {
        font-family: "Roboto";
        font-size: var(--font-size-xl);
        line-height: var(--font-line-xl);
        letter-spacing: 0.1px;
        color: var(--primary-color);
        font-weight: var(--font-weight-bold);
        margin-top: 24px;
    }

    .preferences_services {
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        width: 100vw;
        margin-left: -16px;

        .preferences_services_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 100vw;
            background: transparent;

            .preferences_services_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100vw - 32px);
                height: 70px;
                border-bottom: 1px solid var(--bg-input);

                p {
                    font-family: "Roboto";
                    font-size: var(--font-size-ml);
                    line-height: var(--font-line-ml);
                    letter-spacing: 0.15px;
                    color: var(--primary-color);
                    font-weight: 400 !important;
                }

                .preferences_price {
                    display: flex;
                    align-items: center;

                    p {
                        font-family: "Roboto";
                        font-size: var(--font-size-ml);
                        line-height: var(--font-line-ml);
                        letter-spacing: 0.15px;
                        color: var(--text-color-13);
                    }

                    .sum {
                        display: flex;
                        margin-right: 48px;
                        align-items: center;
                        svg {
                            width: 9px;
                            fill: var(--primary-color);
                        }
                    }

                    .check {
                        margin-left: 24px;

                        svg {
                            width: 24px;
                            height: 24px;

                            path {
                                stroke: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .confirm_preferences {
        background: var(--bg-button-primary);
        position: fixed;
        bottom: 0;
        display: flex;
        height: 56px;
        padding: 12px 0px;
        margin-bottom: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        color: white;
        border: none;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: calc(100vw - 32px);
    }
}

.sum_active {
    margin-right: 0px !important;
}

.last_item {
    border-bottom: none !important;
}

.comment_block {
    display: flex;
    flex-direction: column;

    h1 {
        font-family: "Roboto";
        font-size: var(--font-size-xl);
        line-height: var(--font-line-xl);
        letter-spacing: 0.1px;
        color: var(--primary-color);
        font-weight: var(--font-weight-bold);
        margin-top: 30px;
    }

    .comment_area:hover {
        border: 1px solid var(--border-color-1);
    }

    .comment_area {
        position: relative;
        display: flex;
        margin-top: 35px;
        border: 1px solid var(--secondary-border);
        border-radius: 18px;
        height: 154px;

        textarea {
            background: transparent;
            border: none;
            padding: 16px;
            height: 80px;
            width: 100%;
            color: var(--primary-color);
            font-family: "Roboto";
            font-size: var(--font-size-ml);
            letter-spacing: 0.15px;
            resize: none;
        }

        .text_counter {
            position: absolute;
            bottom: 0;
            margin-right: 16px;
            margin-bottom: 16px;
            right: 0;
            color: #657180;
            font-family: "Roboto";
            font-size: var(--font-size-xs);
            line-height: var(--font-line-xs);
            letter-spacing: 0.4px;
        }

        textarea::placeholder {
            color: var(--secondary-color);
        }
    }

    .comment_services {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        width: 100vw;
        margin-left: -16px;

        .comment_item {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 100vw;
            background: transparent;

            .comment_single_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100vw - 32px);
                height: 70px;
                border-bottom: 1px solid var(--bg-input);

                p {
                    font-family: "Roboto";
                    font-size: var(--font-size-ml);
                    line-height: var(--font-line-ml);
                    letter-spacing: 0.15px;
                    color: var(--primary-color);
                    font-weight: 400 !important;
                }

                .check_icon {
                    margin-left: 24px;

                    svg {
                        width: 24px;
                        height: 24px;

                        path {
                            stroke: #1056BB;
                        }
                    }
                }
            }
        }
    }

    .confirm_comment {
        background: var(--bg-button-primary);
        display: flex;
        position: fixed;
        bottom: 0;
        height: 56px;
        padding: 12px 0px;
        margin-bottom: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        color: white;
        border: none;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: calc(100vw - 32px);
    }
}

button {
    border: none;
}

.active_comment {
    background: rgba(7,130,243,0.2) !important;
    border-radius: 20px;
}

.last_comment {
    border-bottom: none !important;
}

.confirm_block {
    margin-top: auto;
    padding-bottom: 40px;
}

.small_header {
    min-height: 90px !important;
}

.small_layout {
    margin-top: 50px !important;
}