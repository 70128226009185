
.modal {
  display: flex;
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.content_block {
  display: flex;
  flex-direction: column;
  background: var(--bg-modal);
  width: calc(100vw - 48px);
  max-width: 400px;
  height: auto;
  margin: 0 16px;
  border-radius: 28px;
  z-index: var(--modal-z-index);
}

.modal_text {
  font-family: "Roboto";
  font-size: var(--font-size-xl);
  line-height: var(--font-line-xl);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  letter-spacing: 0.1px;
  padding-top: 24px;
  text-align: center;
  padding-top: 24px;
}

.modal_secondary_text {
  font-family: "Roboto";
  font-size: var(--font-size-ml);
  color: var(--primary-color);
  letter-spacing: 0.5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  text-align: center;
}

.modal_main_btn {
  background: transparent;
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: var(--primary-color);
  border: none;
  font-family: Roboto;
  font-size: var(--font-size-s);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: calc(100vw - 48px);
  margin-top: 16px;
  margin-bottom: 16px;
}