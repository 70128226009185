.counterSelectorWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	font-family: "Roboto";

	.inputPhoneWrap {
		display: flex;
		align-items: center;

		.inputPhone {
			text-indent: 0;
			width: 100%;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			padding: 0;
			border-radius: 0;
		}

		input {
			padding: 0;
			text-indent: 0;
			border-radius: 0;
			font: unset;
		}
	}
}

.fullInputWrap {
	display: flex;
	width: 100%;
}

.phoneInputWrap {
	display: flex;
	width: 100%;
	max-width: 60px;
	height: 50px;
	background-color: var(--bg-input);
	border-radius: 10px;

	.phone {
		display: none;
	}

	.dropdown {
		max-height: 250px;

		.search {
			padding: 10px 0;
		}
		.no-entries-message {
			text-align: center;
		}
	}

	.button {
		width: 100%;

		[class~="selected-flag"] {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;

			&::before {
				border: none;
				outline: none;
				box-shadow: none;
			}

			[class~="flag"] {
				margin: 0 auto;
				top: 15px;
				left: 10px;

				[class~="arrow"] {
					margin: 0 0 0 auto;
					left: 15px;
				}
			}
		}

		[class~="country-list"] {
			[class~="search"] {
				[class~="search-box"] {
					width: 93%;
				}
			}
		}
	}

	.disableDropdown {
		[class~="selected-flag"] {
			[class~="flag"] {
				left: 30%;
			}
		}
	}
}
.phoneWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.inputPhoneWrap {
	margin-left: 4px;
	display: flex;
	align-items: center;
	width: 100%;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	background-color: var(--bg-input);
	border-radius: 10px;
	padding: 0;
	height: 50px;

	[class~="input_block"] {
		width: 100%;
		color: var(--text-color-1);
	}
}

.inputCode {
	padding: 0 5px 0 10px;
	color: var(--primary-color);
	font-size: 16px;
}

.error {
	position: absolute;
	bottom: -25px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	color: red;
}
