.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;

  .avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .container {
    display: flex;
    width: 66%;

    .message-image {
      margin-left: 10px;
    }
  }

  .messageContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 22px;
    padding: 10px 16px;
  }

  .postTime {
    font: var(--font-s);
    font-size: 10px;
    font-weight: var(--font-weight-light);
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-left: auto;
  }

  &.supportMessage {
    .messageContent {
      /* flex-direction: column; */
      justify-content: space-between;
      background-color: #eeeeee;
      font: var(--font-s);
      font-size: 13px;
      color: #212121;
      line-height: normal;
      font-weight: var(--font-weight-light);
      letter-spacing: 0.403px;
    }
  }

  &.clientMessage {
    flex-direction: row-reverse;
    .messageContent {
      background-color: #1056BB;

      .messageText {
        font: var(--font-s);
        color: #ffffff;
        line-height: normal;
        font-weight: var(--font-weight-light);
        letter-spacing: 0.403px;
      }

      .postTime {
        color: #ffffff;
      }
    }
  }
}
