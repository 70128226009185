.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-menu);
    border-radius: 28px;

    .icon {
      padding-top: 16.5px;
      width: 27px;
      height: 27px;
    }

    p {
      font: var(--font-ml);
      font-weight: var(--font-weight-light);
      color: var(--primary-color);
      padding-bottom: 8px;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    span {
      font: var(--font-xl);
      font-weight: var(--font-weight-bold);
      color: var(--primary-color);
      padding-bottom: 14.5px;
      line-height: 30px;
      letter-spacing: 0.1px;
    }
  }

.additionalStyleInfo {

  p {
    font: var(--font-m);
    padding-bottom: 4px;
  }

  span {
    font: var(--font-l);
    font-size: 19px;
    font-weight: var(--font-weight-bold);
    padding-bottom: 8px;
  }
}