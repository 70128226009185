.markerElement {
	background-size: contain;
	background-repeat: no-repeat;
	width: 50px;
	height: 50px;
	z-index: 1;
	box-shadow: none;
}

.pointMarker {
	background-image: url("../../../../images/MyGeolocation.svg");
}

.pointMarkerBlue {
	z-index: 1;
	background-image: url("../../../../images/MyGeolocation.svg");
}

.pointMarkerGrey {
	background-image: url("../../../../images/MyGeolocationGrey.svg");
}

.animateMarker {
	position: relative;
	animation: markerAnimation 0.3s ease-in-out forwards;
}

.animateMarker::after {
	content: "";
	position: fixed;
	bottom: -15px;
	left: 50%;
	transform: translateX(-79%);
	width: 60%;
	height: 10px;
	border-radius: 50%;
	background-color: var(--shadow-color);
	transition: background-color 0.1s ease-in-out;
}

@keyframes markerAnimation {
	0% {
		top: -7px;
	}
	100% {
		top: -7px;
	}
}

@keyframes pulse2km {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(10);
		opacity: 0;
	}
}

@keyframes pulse6km {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(15);
		opacity: 0;
	}
}

@keyframes pulse10km {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(20);
		opacity: 0;
	}
}

.pointMarkerPulse {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 60%;
		left: 10%;
		width: 20px;
		height: 20px;
		background: rgba(0, 122, 255, 0.5);
		border-radius: 50%;
		animation: pulse2km 1.5s infinite;
		transform: translate(-50%, -50%);
	}
}

.pointMarkerPulse6km {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 60%;
		left: 10%;
		width: 20px;
		height: 20px;
		background: rgba(0, 122, 255, 0.5);
		border-radius: 50%;
		animation: pulse6km 1.5s infinite;
		transform: translate(-50%, -50%);
	}
}

.pointMarkerPulse10km {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 60%;
		left: 10%;
		width: 20px;
		height: 20px;
		background: rgba(0, 122, 255, 0.5);
		border-radius: 50%;
		animation: pulse10km 1.5s infinite;
		transform: translate(-50%, -50%);
	}
}
