.square {
	margin: 25px 0;
  width: 22px;
  height: 22px;
  background-color: var(--primary-color);
  border-radius: 20%;
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
