.singleSuggestion {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    border-bottom: 1px solid var(--bg-input);
    padding: 8px 0;

    svg {
        padding-left: 12px;
    }

    path {
        fill: var(--primary-color) !important;
    }
}

.textBlock {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    width: calc(100% - 60px);

    .mainTextSuggest {
        font: var(--font-s);
        color: var(--primary-color);
        letter-spacing: 0.15px;
    }

    .secondaryTextSuggest {
        font: var(--font-s);
        color: var(--text-color-2);
        letter-spacing: 0.15px;
    }
}