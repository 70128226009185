.AutocompleteWrapper {
  flex: 1;
  padding-right: 10px;

  .input {
    flex: 1;
    border: none;
    background: transparent;
    color: var(--primary-color);
    font: var(--font-ml);
    letter-spacing: .15px;
    padding: 0;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: var(--primary-icon);
    }
  }

  .suggestion {
    background-color: var(--bg-color-primary);
    z-index: 1000;
    max-height: 320px;
    border-radius: 20px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    bottom: 49px;
    left: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    li {
      cursor: pointer;
      padding: 10px;
      font: var(--font-s);
      color: var(--primary-color);
      border-bottom: 1px solid var(--border-color-4);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
