.menuHeader {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu__button {
  margin-top: 70px;
  margin-left: 16px;
  position: fixed;
  z-index: 10;
  border-radius: 30%;
  border: none;
  background: var(--bg-color-primary);
  width: 44px;
  height: 44px;
  color: black;
  outline: none !important;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

  .menu_icon {
    width: 24px;
    height: 24px;
    transform: translate(42%, 42%);

    .icon_menu_btn {
      stroke: var(--primary-color);
    }
  }
}

.menuWrapper {
  & .MuiDrawer-paper {
    width: 280px;
    background-color: var(--bg-color-primary);
  }

  & .MuiButtonBase-root {
    border-bottom: 1px solid red;
  }

  & .MuiListItemIcon-root {
    min-width: 35px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.3);
  }
}

//TODO Подлкючить модульные стили
