.add_card_btn {
  background: transparent;
  display: flex;
  height: 56px;
  padding: 12px 0px;
  margin-top: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  margin-left: 16px;
  color: white;
  border: none;
  width: calc(100vw - 32px);

  p {
    font-family: "Roboto";
    font-size: var(--font-size-ml);
    line-height: var(--font-line-ml);
    letter-spacing: 0.15px;
    color: var(--primary-color);
    padding-left: 12px;
  }

  svg {
    path {
      stroke: var(--primary-color);
    }
  }
}
