.SmsCodeWrapper {
  padding: var(--padding-vertical);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

.title {
  padding-bottom: 20px;
  font: var(--font-xl);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.info {
  margin-bottom: 120px;
  h2 {
    padding-bottom: 10px;
    font: var(--font-xl);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
  }

  .codeInfo {
    font: var(--font-m);
    font-weight: var(--font-weight-light);
    color: var(--text-color-1);
  }

  .phone {
    font: var(--font-m);
    font-weight: var(--font-weight-bold);
    color: var(--text-color-1);
  }
}

.errorCode {
  padding-top: 10px;
  text-align: center;
  font: var(--font-m);
  font-weight: var(--font-weight-light);
  color: #ff4d4f;
}

.newCode {
  text-align: center;
  margin: 72px 20px 0 20px;
  font: var(--font-m);
  font-weight: var(--font-weight-light);
  color: var(--text-color-1);
}

.modalIcon {
  width: 22px;
  height: 22px;
  stroke: var(--primary-color);
}

.continueBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
}