.EditProfileWrapper {
	padding-top: 18px;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 130px);
}

.photoBlock {
	position: relative;
	background-color: var(--bg-menu);
	border-radius: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 47px 0;

	.photo {
		width: 80px;
		height: 80px;

		.userIcon {
			stroke: var(--inverted-primary-color);
		}

		.cameraIcon {
			position: absolute;
			top: 54px;
			right: 0;
		}
	}
}

.profileField {
	margin-top: 3px;
	.input {
		margin-top: 20px;
	}

	.inputPhone {
		display: flex;
		margin-top: 22px;
	}
}

.saveBtn {
	margin-top: auto;
	button {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.modalIcon {
	fill: var(--primary-color);
}
