.AdressesAccountWrapper {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .btnBlock {
    display: flex;
    justify-content: center;
    margin-top: auto;
  
    .confirmBtn {
      margin-bottom: 25px;
    }
  }