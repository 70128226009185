.overlayClassname {
	position: absolute;
	bottom: 30px;
}

.contentStyles {
	background-color: var(--bg-color-modal);
}

.modalError {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.acceptBtn {
	font: var(--font-l);
	font-weight: 700;
	background-color: var(--bg-button-secondary);
	padding: 17px 0;
}

.supportBtn {
	display: flex;
	justify-content: center;
	gap: 10px;
	font: var(--font-ml);
}

.unexpectedError {
	text-align: center;
	font: var(--font-xl);
	font-weight: bold;
	color: var(--primary-color);
}

.contentError {
	font: var(--font-ml);
	text-align: center;
	font-weight: 400;
	color: var(--primary-color);
}

.icon {
	path {
		fill: var(--primary-color);
	}
}