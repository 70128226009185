.menuHelpLink {
	margin: 0;
}

.menuButton {
	padding: 24px 0 20px 0;
	border-bottom: 1px solid var(--secondary-border);
}

.icon {
	path {
		fill: var(--primary-color);
	}
}
