/**
 Fonts */
$font-stack: 'Oswald', sans-serif;
$secondary-font-stack: 'Montserrat';

/**
 Sizes */
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;

/**
 Colors */
$primary-color-1: #3BB525;
$primary-color-2: #DC7927;
$primary-color-3: #3975C9;
$primary-color-4: #7B63BC;
$primary-text-color: #6d6d6d;
$secondary-color-white: #ffffff;
$secondary-color-black: #000000;

/**
 Animation */
$base-transition: 0.3s;

/**
  Radius buttons */
$button-border: 8px;
