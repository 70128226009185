.arrow_back {
    display: flex;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background: var(--bg-color-primary);
    margin-top: 70px;
    margin-left: 16px;
    position: fixed;
    z-index: 10;
    border-radius: 30%;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

    svg {
        margin-left: -1px;
        width: 20px;
        height: 20px;
    }

    path {
        stroke: var(--primary-color);
    }
}

.placeWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: auto;
    background: var(--bg-color-primary);
    border-radius: 28px 28px 0 0;
    padding: 0px 16px 40px 16px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
    z-index: 9999;
    
    .selected_addresses {
        display: flex;
        padding-top: 24px;
        align-items: center;
        gap: 10px;

        svg {
            margin-top: -1px;
        }

        path {
            fill: var(--primary-color);
        }

        p {
            color: var(--primary-color);
            font: var(--font-l);
            font-weight: var(--font-weight-bold);
            letter-spacing: 0.15px;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
        }
    }

    .confirm_place {
        font-family: "Roboto";
        font-size: var(--font-size-ml);
        letter-spacing: 0.5px;
        color: var(--text-color-14);
        padding: 24px 0 30px 0;
    }
}