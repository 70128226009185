.AddressPointWrapper {
  display: flex;
  margin-bottom: 10px;
  position: relative;
}

.points {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: transparent;
  border: 1px solid var(--border-color-1);
  border-radius: 16px;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: var(--bg-input);
  border-radius: 16px;
  border: none;
}

.rescalePoint {
  width: 87%;
}

.iconLeft,
.iconRight {
  display: flex;
  cursor: pointer;
}

.iconRight {
  svg {
    path {
      stroke: var(--primary-color);
    }
  }
  margin: 0 7px 0 0;
}

.isSelectedPoint {
  display: flex;
  align-items: center;
}

.hideClose {
  display: none;
}

.additionStyle {
  margin: 0 4px 0 5px;

  rect {
    stroke: var(--primary-color);
  }
}

.selectedPointIcon {
  margin: 0 9px 0 11px;

  path {
    fill: var(--primary-color);
  }
}

.selectedPoint {
  background: var(--bg-input);
  border-radius: 16px;
  border: none;
}

.firstPoint {
    margin: 0 8px 0 10px;
}

.add_point {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 12px 4px 12px 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    stroke: var(--primary-color);
  }
}

.activePoint {
  display: none;
}

.highlightedInput {
  animation: highlightInput 1s ease-in-out;
}

.bottom_btn {
  position: fixed;
  width: calc(100% - 32px);
  margin-bottom: 40px;
  bottom: 0;
}

@keyframes highlightInput {
  0% {
    background-color: var(--bg-input);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.15);
  }
  100% {
    background-color: var(--bg-input);
  }
}