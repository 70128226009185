.OrderDiscountWrapper {
  position: absolute;
  background: var(--linear-gradient-blue);
  border-radius: 28px 28px 0px 0px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
  width: 100%;
  height: 60px;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    top 0.3s ease;
}

.active {
  opacity: 1;
  top: -25px;
}

.discount {
  display: flex;
  justify-content: center;
  margin-top: 5px;

  p {
    font: var(--font-xs);
    font-weight: var(--font-weight-light);
    color: var(--text-color-8);
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}
