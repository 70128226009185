.SettingWrapper {
  padding: 12px 0;
}

.menuItem {
 padding: 24px 0 20px 0;
 border-bottom: 1px solid var(--secondary-border);
}

.globeIcon {
  stroke: var(--primary-color);
}

.icon {
  fill: var(--primary-color);
}